import { useKeycloak } from "@react-keycloak/web";
import React from "react";
import { Outlet } from "react-router-dom";

const RequireAuth = () => {
  const { keycloak } = useKeycloak();

  if (!keycloak.authenticated) {
    // Redirect to Keycloak's registration page
    keycloak.register({ redirectUri: window.location.origin + window.location.pathname });
    return null; // Return null since keycloak.register will handle the redirect
  }

  return <Outlet />;
};

export default RequireAuth;
