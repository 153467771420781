import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/react';

// Attempt to import version, fallback to local value
let version = 'local';
try {
    const versionInfo = require('../version.json');
    version = versionInfo.version;
} catch (e) {
    console.warn('version.json not found, using local version');
}

export const initSentry = () => {
    if (process.env.NODE_ENV === 'production') {
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            integrations: [
                browserTracingIntegration(),
            ],
            tracesSampleRate: 1.0,
            environment: process.env.NODE_ENV,
            release: version,
            enabled: process.env.NODE_ENV === 'production',
            beforeSend(event) {
                return event;
            },
        });
    }
};

export const captureException = (error: Error) => {
    Sentry.captureException(error);
};
