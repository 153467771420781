/**
 * Custom React hook to fetch and manage the authenticated user's profile information
 * using Keycloak and the `@react-keycloak/web` library.
 *
 * This hook retrieves the user profile after Keycloak has been initialized and updates
 * the state with the user information.
 */
import { UserInfo } from "@api/api";
import { useEffect, useState } from "react";
import keycloak from "../Keycloak";
import { useKeycloak } from "@react-keycloak/web";

/**
 * A custom hook to fetch and manage the authenticated user's profile information.
 *
 * @example
 * const userProfile = useUserProfile();
 *
 * if (userProfile) {
 *   console.log(`User Name: ${userProfile.name}`);
 * }
 */
export const useUserProfile = () => {
  const { initialized } = useKeycloak();
  const [userProfile, setUserProfile] = useState<UserInfo | undefined>();

  useEffect(() => {
    /**
     * Fetches the user's profile information from Keycloak and updates the state.
     *
     * @async
     */
    const fetchUserProfile = async () => {
      const profile = await keycloak.loadUserInfo();
      setUserProfile(profile as UserInfo);
    };

    if (initialized) {
      fetchUserProfile().catch((err) => {
        console.error(err);
      });
    }
  }, [initialized]);

  return userProfile;
};
