import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  DiscountCode,
  getCurrentOrder,
  initializeOrder,
  Order,
  patchOrder,
  getCurrentRestaurant,
} from "@api/api";
import { omit } from "lodash";
import { useKeycloak } from "@react-keycloak/web";
import keycloak from "../../Keycloak";
import { useUserProfile } from "../../hooks/useUserProfile";

export interface SocialPage {
  pageId: string;
  name: string;
  instagramId?: string;
  instagramName?: string;
}

interface StepsContextInterface {
  restaurantInfo: null | string;
  setRestaurantAddress: (value: null | string) => void;
  searchYourBusinessPopUpOpen: boolean;
  setSearchYourBusinessPopUpOpen: (value: boolean) => void;
  socialMediaPopUpOpen: boolean;
  setSocialMediaPopUpOpen: (value: boolean) => void;
  planType: string;
  setPlanType: (value: string) => void;
  annualPlanPrice: number;
  setAnnualPlanPrice: (value: number) => void;
  monthlyPlanPrice: number;
  setMonthlyPlanPrice: (value: number) => void;
  packages: string[];
  setPackages: (value: string[]) => void;
  submitForm: (() => void) | null;
  setSubmitForm: (submitForm: (() => void) | null) => void;
  restaurantInfoForOrder: object;
  setRestaurantInfoForOrder: (value: object) => void;
  authToken: undefined | string;
  setAuthToken: (value: undefined | string) => void;
  userId: string;
  setUserId: (value: string) => void;
  socialPages: [any];
  domainName: string;
  setDomainName: (value: string) => void;
  setSocialPages: (value: object) => void;
  selectedFacebookPage: SocialPage | null;
  setSelectedFacebookPage: React.Dispatch<
    React.SetStateAction<SocialPage | null>
  >;
  selectedInstagramPage: SocialPage | null;
  setSelectedInstagramPage: React.Dispatch<
    React.SetStateAction<SocialPage | null>
  >;
  setIsAllPreferencesChecked: React.Dispatch<React.SetStateAction<boolean>>;
  isAllPreferencesChecked: boolean;
  order: Order | null;
  setOrder: React.Dispatch<React.SetStateAction<any>>;
  saveOrder: (orderId: string, data: any) => Promise<Order>;
  discountCode?: DiscountCode;
  setDiscountCode?: (value?: DiscountCode) => void;
}

export const StepsContext = createContext<StepsContextInterface>({
  restaurantInfo: "",
  setRestaurantAddress: () => {},
  searchYourBusinessPopUpOpen: false,
  setSearchYourBusinessPopUpOpen: () => {},
  socialMediaPopUpOpen: false,
  setSocialMediaPopUpOpen: () => {},
  planType: "annual",
  setPlanType: () => {},
  annualPlanPrice: 220,
  setAnnualPlanPrice: () => {},
  monthlyPlanPrice: 250,
  setMonthlyPlanPrice: () => {},
  packages: [],
  setPackages: () => [],
  submitForm: null,
  setSubmitForm: () => {},
  restaurantInfoForOrder: {},
  setRestaurantInfoForOrder: () => {},
  authToken: "",
  setAuthToken: () => {},
  userId: "",
  setUserId: () => {},
  socialPages: [{}],
  setSocialPages: () => {},
  domainName: "",
  setDomainName: () => {},
  selectedFacebookPage: null,
  setSelectedFacebookPage: () => {},
  selectedInstagramPage: null,
  setSelectedInstagramPage: () => {},
  isAllPreferencesChecked: false,
  setIsAllPreferencesChecked: () => {},
  order: null,
  setOrder: () => {},
  saveOrder: async () => {
    return {} as Order;
  },
  discountCode: undefined,
  setDiscountCode: () => {},
});

export const StepsContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { initialized } = useKeycloak();
  const [restaurantAddress, setRestaurantAddress] = useState<null | string>(
    null
  );
  const [searchYourBusinessPopUpOpen, setSearchYourBusinessPopUpOpen] =
    useState<boolean>(false);
  const [socialMediaPopUpOpen, setSocialMediaPopUpOpen] =
    useState<boolean>(false);
  const [planType, setPlanType] = useState<string>("annual");
  const [annualPlanPrice, setAnnualPlanPrice] = useState<number>(220);
  const [monthlyPlanPrice, setMonthlyPlanPrice] = useState<number>(250);
  const [packages, setPackages] = useState<string[]>([]);
  const [submitForm, setSubmitForm] = useState<(() => void) | null>(null);
  const [restaurantInfoForOrder, setRestaurantInfoForOrder] = useState<any>();
  const [authToken, setAuthToken] = useState<undefined | string>("");
  const [userId, setUserId] = useState<string>("");
  const [socialPages, setSocialPages] = useState<any>([]);
  const [domainName, setDomainName] = useState<string>("");
  const [selectedFacebookPage, setSelectedFacebookPage] =
    useState<SocialPage | null>(null);
  const [selectedInstagramPage, setSelectedInstagramPage] =
    useState<SocialPage | null>(null);
  const [isAllPreferencesChecked, setIsAllPreferencesChecked] =
    useState<boolean>(false);
  const [order, setOrder] = useState<Order | null>();
  const [discountCode, setDiscountCode] = useState<DiscountCode | undefined>(
    undefined
  );

  const userProfile = useUserProfile();

  useEffect(() => {
    const authToken = sessionStorage.getItem("authToken");

    if (authToken) {
      console.log("Setting authToken from session storage");
      setAuthToken(authToken);
    } else {
      // attempt to get the token from keycloak
      const authToken = keycloak.token;
      if (authToken) {
        console.log("Setting authToken from keycloak");
        setAuthToken(authToken);
        sessionStorage.setItem("authToken", authToken);
      }
    }
  }, [initialized]);

  useEffect(() => {
    const loadOrder = async () => {
      // first check if there is a current order
      let currentOrder = await getCurrentOrder(authToken || "");

      if (!currentOrder) {
        const newOrder = await initializeOrder(authToken || "");

        currentOrder = newOrder;
      }

      setOrder(currentOrder!);

      return currentOrder;
    };

    if (authToken && userProfile?.sub) {
      loadOrder().catch((err) => {
        console.error(err);
      });
    }
  }, [authToken, userProfile?.sub]);

  useEffect(() => {
    const checkCurrentRestaurant = async () => {
      if (!authToken) return;

      try {
        const restaurant = await getCurrentRestaurant(authToken);
        if (restaurant) {
          window.location.href = process.env.REACT_APP_MANAGER_URL || "";
        }
      } catch (error) {
        console.log("No existing restaurant found");
      }
    };

    checkCurrentRestaurant();
  }, [authToken]);

  const saveOrderAbortController = useRef(new AbortController());

  const saveOrder = useCallback(
    async (orderId: string, data: any): Promise<Order> => {
      saveOrderAbortController.current.abort();

      saveOrderAbortController.current = new AbortController();

      const previousOrder = order;

      setOrder((currentOrder) => {
        if (!currentOrder) return null;
        return {
          ...currentOrder,
          ...omit(data, ["restaurantInfoDto"]),
          metadata: {
            ...(currentOrder.metadata || {}),
            restaurantInfo: {
              ...((currentOrder.metadata || {}).restaurantInfo || {}),
              ...data.restaurantInfoDto,
            },
          },
        } as Order;
      });

      try {
        // Attempt to patch the order
        const patchedOrder = await patchOrder(
          orderId,
          data,
          authToken || "",
          saveOrderAbortController.current.signal
        );

        if (!patchedOrder) {
          throw new Error("Failed to patch order");
        }

        setOrder(patchedOrder as Order);
        return patchedOrder as Order;
      } catch (error) {
        // Ignore cancellation errors
        if ((error as Error).name === "CanceledError") {
          return previousOrder as Order;
        }

        setOrder(previousOrder);
        // Re-throw the error so it can be handled by the caller if needed
        throw error;
      }
    },
    [authToken, order]
  );

  const value = {
    restaurantInfo: restaurantAddress,
    setRestaurantInfo: setRestaurantAddress,
    searchYourBusinessPopUpOpen,
    setSearchYourBusinessPopUpOpen,
    socialMediaPopUpOpen,
    setSocialMediaPopUpOpen,
    planType,
    setPlanType,
    annualPlanPrice,
    setAnnualPlanPrice,
    monthlyPlanPrice,
    setMonthlyPlanPrice,
    packages,
    setPackages,
    submitForm,
    setSubmitForm,
    restaurantInfoForOrder,
    setRestaurantInfoForOrder,
    authToken,
    setAuthToken,
    userId,
    setUserId,
    socialPages,
    setSocialPages,
    domainName,
    setDomainName,
    selectedFacebookPage,
    setSelectedFacebookPage,
    selectedInstagramPage,
    setSelectedInstagramPage,
    isAllPreferencesChecked,
    setIsAllPreferencesChecked,
    order: order ?? null,
    setOrder,
    saveOrder,
    restaurantAddress,
    setRestaurantAddress,
    discountCode,
    setDiscountCode,
  };

  return (
    <StepsContext.Provider value={value}>{children}</StepsContext.Provider>
  );
};
